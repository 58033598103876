<template>
  <BContainer id="profile">
    <BRow>
      <BCol cols="12">
        <h3 class="my-4">
          My Profile
        </h3>
      </BCol>
      <BCol cols="6">
        <Bubblewrap :info="apiCall">
          <template #placeholder>
            <BTableSimple>
              <BTbody>
                <BTr>
                  <BTh>
                    User ID
                  </BTh>
                  <BTd>
                    {{ id }}
                  </BTd>
                </BTr>
                <BTr>
                  <BTh>
                    User Created
                  </BTh>
                  <BTd>
                    <ContentLoader
                      :height="5"
                      :width="100"
                    />
                  </BTd>
                </BTr>
                <BTr>
                  <BTh>
                    Last Login
                  </BTh>
                  <BTd>
                    <ContentLoader
                      :height="5"
                      :width="100"
                    />
                  </BTd>
                </BTr>
              </BTbody>
            </BTableSimple>
          </template>
          <template #loaded>
            <BTableSimple>
              <BTbody>
                <BTr>
                  <BTh>
                    User ID
                  </BTh>
                  <BTd>
                    {{ id }}
                  </BTd>
                </BTr>
                <BTr>
                  <BTh>
                    User Created
                  </BTh>
                  <BTd
                    class="created-timestamp"
                  >
                    {{ $filters.formatDate(userDetails.createdTimestamp) }}
                  </BTd>
                </BTr>
                <BTr>
                  <BTh>
                    Last Login
                  </BTh>
                  <BTd
                    class="last-login-time"
                  >
                    {{ $filters.formatDate(userDetails.lastLoginTime) }}
                  </BTd>
                </BTr>
              </BTbody>
            </BTableSimple>
          </template>
        </Bubblewrap>
      </BCol>
    </BRow>
  </BContainer>
</template>

<script setup lang="ts">
import axios from 'axios';
import {
  computed, onMounted, ref,
} from 'vue';
import {
  BCol, BRow, BContainer, BTableSimple,
  BTbody, BTr, BTd, BTh,
} from 'bootstrap-vue-next';
import Bubblewrap from '@/components/BubblewrapComponent.vue';
import { UserDetails } from '@/models/userDetails';
import { getKeycloakRequestConfig } from '@/utils/http';
import { userStore } from '@/store/modules/user';
import { useApiCall } from '@/composable/apiStates';

const userDetails = ref(null);
const user = userStore();
// const isRowHeader = ref(true); // Set this to false if it's a column header
const id = computed(() => user.id);
const apiCall = useApiCall();

/**
   * fetching user details from the API
   */
async function fetchUserDetails(): Promise<UserDetails> {
  const res = await axios
    .get<UserDetails>('/user', getKeycloakRequestConfig(user.xsrfToken))
    .then((response) => response.data);

  return {
    lastLoginTime: new Date(res.lastLoginTime),
    createdTimestamp: new Date(res.createdTimestamp),
  };
}

onMounted(async () => {
  try {
    userDetails.value = await fetchUserDetails();
  } catch (e) {
    console.log(e);
    apiCall.error = e;
  }
  apiCall.loaded = true;
});

</script>
