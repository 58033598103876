import { defineStore } from 'pinia';

interface AppState {
  appVersion: string;
}
const appUseStore = defineStore('app', {
  state: (): AppState => ({
    appVersion: process.env.APP_VERSION,
  }),
  getters: {
    getVersion(state) {
      return state.appVersion;
    },
  },
});
export default appUseStore;
