<template>
  <div>
    <button
      id="login"
      class="btn btn-primary my-2 my-sm-0"
      type="button"
      @click="login()"
    >
      Login
    </button>
  </div>
</template>

<script setup lang="ts">
import { userStore } from '@/store/modules/user';

const user = userStore();
function login() {
  user.redirectToLogin();
}

</script>
