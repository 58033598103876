interface AccountInformation {
  accountAlias: string;
  accountID: string;
  vpcCIDR: string;
  vpcAZs: number;
  mpsConnectEnabled: boolean;
  mpsConnectCIDR?: string;
}

// eslint-disable-next-line no-shadow
export enum SelectOptionsDropDown {
  ROLE = 'Choose a role...',
  EXPIRES = 'Expires in',
  DURATION = 'Choose the duration...'
}

type TenantContacts = {
  [key: string]: string[];
};

interface ArtifactoryRepositories {
  releaseRepo: string[];
  snapshotRepo: string[];
  virtualRepo: string[];
}

export interface DropDownList {
  item: string,
  title: string,
  disabled: boolean,
}

export interface TenantRole {
  name: string;
  technicalName: string;
  hasFirefighterRole: boolean;
  isSelfServiceRole: boolean;
}

export interface TenantInformation {
  region: string;
  complianceProfile: string;
  artifactoryRepositories: ArtifactoryRepositories;
  quickSight: boolean;

  accounts: AccountInformation[];
  roles: TenantRole[];
  contacts: TenantContacts;
}
