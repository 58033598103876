import { defineStore } from 'pinia';
import { Permission, TenantRole, User } from '../../models/bulkPermissionManagement';

export const useBulkPermissionManagement = defineStore('useBulkPermissionManagement', {
  state: () => ({
    selectedUsers: [] as User[],
    selectedTenantRoles: [] as TenantRole[],
  }),

  getters: {
    permissions(state): Permission[] {
      return state.selectedUsers
        .map((user) => (state.selectedTenantRoles
          .map(({ role, tenant }) => ({
            tenant,
            role,
            user,
          }))
        )).flat();
    },
    isValid(state): boolean {
      return state.selectedUsers.length > 0 && state.selectedTenantRoles.length > 0;
    },
  },
});
