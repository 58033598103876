<template>
  <div class="w-100 h-100 d-flex flex-column align-items-center justify-content-center" :style="minHeightStyle">
    <BSpinner type="grow" />
    <h3 v-if="!!text" class="mt-2">
      {{ text }}
    </h3>
  </div>
</template>

<script setup lang="ts">
import { BSpinner } from 'bootstrap-vue-next';

type Props = {
  text?: string;
  minHeight?: string | number;
}

const { text, minHeight = '6rem' } = defineProps<Props>();

const minHeightStyle = {
  minHeight: minHeight === 'number' ? `${minHeight}px` : minHeight,
};

</script>
