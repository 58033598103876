<template>
  <div
    id="home"
    class="col-12 mt-4 pt-4"
  >
    <div class="p-5 mb-4 bg-light rounded-3">
      <div class="container-fluid py-5">
        <h1 class="display-4 px-5">
          Welcome to the MPS-NotAPortal
        </h1>
        <p class="lead px-5">
          To get started, log in through the button in the upper right corner.
        </p>
      </div>
    </div>
    <div class="p-5 mb-4 rounded-3 bg-white">
      <div class="container-fluid py-5">
        <h1 class="display-4 px-5">
          What is this?
        </h1>
        <p class="lead px-5">
          This is the MPS-NotAPortal for customer-self-service. With this portal your
          tenant-approver can manage user access for your team.<br>
          You can find more information <a
            href="https://docs.platform.vwfs.io/docs/nota/managing_a_tenant/"
            target="_blank"
            rel="noopener noreferrer"
          >here</a>.
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

</script>

<style lang="sass" scoped>
#home.mt-4 {
  margin-top: 0 !important
}
</style>
