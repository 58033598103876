<template>
  <BFormSelect
    v-model="selectedValue"
    :options="options"
    class="mb-3 custom-select"
    value-field="title"
    text-field="item"
    disabled-field="disabled"
    @change="$emit('selectValue', selectedValue)"
  />
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { BFormSelect } from 'bootstrap-vue-next';

const props = defineProps({
  defaultOption: {
    type: String,
    required: true,
  },
  options: {
    type: Array,
    required: true,
  },
});

const selectedValue = ref(props.defaultOption);
</script>
