<template>
  <div>
    <BForm @submit.prevent="() => { canAddUser && clickAdd() }">
      <BInputGroup>
        <BFormInput
          id="onboarding-wizard-select-multiple-users"
          ref="emailInputElement"
          v-model="input.email"
          list="onboarding-wizard-suggestions"
          placeholder="email address"
        />
        <datalist id="onboarding-wizard-suggestions">
          <option
            v-for="suggestion in suggestions"
            :key="suggestion"
          >
            {{ suggestion }}
          </option>
        </datalist>
        <BButton
          :disabled="!canAddUser"
          @click="clickAdd"
        >
          {{ loadingSuggestions ? 'Loading...' : 'Add' }}
        </BButton>
      </BInputGroup>
      <BListGroup class="mt-3">
        <BListGroupItem
          v-for="user in selectedUsers"
          :key="user.id"
          class="d-flex justify-content-between flex-end"
        >
          {{ user.email }}
          <BButton
            pill
            size="sm"
            variant="outline-danger"
            @click="clickRemoveUser(user)"
          >
            Remove
          </BButton>
        </BListGroupItem>
        <BListGroupItem
          v-if="selectedUsers.length === 0"
          class="text-center"
        >
          <span class="my-2 m-0">
            Type an email address to add a user to the list.
          </span>
          <BButton
            pill
            size="sm"
            variant="outline-danger"
            style="visibility: hidden;"
          >
            Remove
          </BButton>
        </BListGroupItem>
      </BListGroup>
    </BForm>
  </div>
</template>

<script setup lang="ts">
import { ref, reactive, computed } from 'vue';
import {
  BListGroup, BButton, BForm, BFormInput, BInputGroup, BListGroupItem,
} from 'bootstrap-vue-next';
import { storeToRefs } from 'pinia';
import { watchDebounced } from '@vueuse/core';
import { fetchUsers } from '@/api/bulkPermissionManagement';
import { userStore as useUserStore } from '@/store/modules/user';
import { useBulkPermissionManagement } from '@/store/modules/useBulkPermissionManagement';
import { User } from '@/models/bulkPermissionManagement';

const store = useBulkPermissionManagement();
const { selectedUsers } = storeToRefs(store);

const userStore = useUserStore();

const emailInputElement = ref(null);

const input = reactive({ email: '' });

const isValidUser = ref(false);

const isLoggedInUser = ref(false);

const loadingSuggestions = ref(false);

const canAddUser = computed(() => isValidUser.value
  && !loadingSuggestions.value
  && !isLoggedInUser.value);

const suggestions = ref<string[]>([]);

const getUsers = async (email: string) => {
  if (email.length > 2) {
    const users = await fetchUsers(email);

    return users;
  }
  return [];
};

const isASelectedUser = (email: string) => selectedUsers.value
  .map((user) => user.email)
  .includes(email);

const clickAdd = async () => {
  if (!canAddUser.value) {
    return;
  }

  const allUsers = await getUsers(input.email);

  if (isASelectedUser(input.email)) {
    return;
  }

  selectedUsers.value.push(allUsers[0]);

  input.email = '';
  emailInputElement.value.focus();
};

const clickRemoveUser = (user) => {
  selectedUsers.value.splice(selectedUsers.value.indexOf(user), 1);
  input.email = '';
  emailInputElement.value.focus();
};

const loadSuggestions = (userSuggestions: User[], email: string) => {
  const suggestedUsers = userSuggestions.map((suggestion) => suggestion.email);
  const emailsOfSelectedUsers = selectedUsers.value.map((user) => user.email);

  suggestions.value = suggestedUsers.filter((e) => emailsOfSelectedUsers.indexOf(e) === -1);

  // don't show suggestions if the user the user has typed is the only suggestion
  if (suggestions.value.length === 1 && suggestions.value[0] === email) {
    suggestions.value = [];
  }
};

watchDebounced(
  input,
  async () => {
    loadingSuggestions.value = true;

    const userSuggestions = await getUsers(input.email);

    loadSuggestions(userSuggestions, input.email);

    isValidUser.value = userSuggestions.length === 1
      && input.email === userSuggestions[0].email;

    isLoggedInUser.value = userSuggestions.length === 1
      && userStore.id === userSuggestions[0].id;

    loadingSuggestions.value = false;
  },
  { debounce: 350 },
);
</script>
