// eslint-disable-next-line import/no-extraneous-dependencies
const moment = require('moment');

export enum SortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export default class Utils {
  public static toDate(date: string, time: string, timezone?: string) {
    if (date === null || time === null) {
      return null;
    }

    let parseString = 'YYYY-MM-DD HH:mm';
    let testString = `${date} ${time}`;
    if (timezone) {
      parseString += ' XXX';
      testString += ` ${timezone}`;
      console.log(testString);
    }
    const parsedDate = moment(testString, parseString);
    const currentDate = moment().parseZone();
    const isFuture = parsedDate.isAfter(currentDate, 'day');
    if (!isFuture) {
      return parsedDate.hours() > currentDate.hours() && parsedDate.date() === currentDate.date();
    }
    return isFuture;
  }

  // Debounce function that delays the execution of the passed function
  public static debounce(fn: Function, delay: number) {
    let timeoutId: number | undefined;
    return (...args: any[]) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        // eslint-disable-next-line prefer-spread
        fn.apply(null, args);
      }, delay);
    };
  }

  public static sort<T = string | number>(arr: T[], order: SortOrder = SortOrder.ASC) {
    const arrCopy = [...arr];

    arrCopy.sort((a, b) => {
      if (typeof a === 'number' && typeof b === 'number') {
        return order === SortOrder.ASC ? a - b : b - a;
      }
      if (typeof a === 'string' && typeof b === 'string') {
        return order === SortOrder.ASC ? a.localeCompare(b) : b.localeCompare(a);
      }
      throw new Error('Array must contain either all strings or all numbers.');
    });

    return arrCopy;
  }

  public static sortBy<T extends {}>(arr: T[], key: keyof T, order: SortOrder = SortOrder.ASC) {
    const arrCopy = [...arr];

    arrCopy.sort((a, b) => {
      if (a[key] < b[key]) {
        return order === SortOrder.ASC ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return order === SortOrder.ASC ? 1 : -1;
      }
      return 0;
    });

    return arrCopy;
  }
}
