<template>
  <div>
    <BButton
      :disabled="props.tenantInfo.roles.length === 0"
      @click="showModal()"
    >
      <span><font-awesome-icon
        class="cert-button-icon"
        icon="fire-alt"
      /></span>
      Request Firefighter
    </BButton>
    <BModal
      :id="modalId"
      ref="modalRef"
      :hide-header="true"
      :hide-footer="true"
      size="lg"
    >
      <h3 class="my-4">
        Request Firefighter Access
      </h3>
      <div class="form-row form-group">
        <BCol>
          <DropDownListComponent
            v-model="requestRole"
            name="role"
            :default-option="SelectOptionsDropDown.ROLE"
            :options="roleOptions"
            @select-value="updateRole"
          />
          <div
            v-if="!requestRoleIsValid"
            class="invalid-feedback d-block"
          >
            Please select a role.
          </div>
        </BCol>
      </div>
      <div class="form-row form-group">
        <BCol class="mb-3">
          <BFormTextarea
            v-model="requestReason"
            placeholder="Reason..."
          />
          <div
            v-if="!requestReasonIsValid"
            class="invalid-feedback d-block"
          >
            Please enter a reason.
          </div>
        </BCol>
      </div>
      <div class="form-row form-group">
        <BCol>
          <DropDownListComponent
            v-model="requestDuration"
            name="duration"
            :default-option="SelectOptionsDropDown.DURATION"
            :options="durationOptions"
            @select-value="updateDuration"
          />
          <div
            v-if="!requestDurationIsValid"
            class="invalid-feedback d-block"
          >
            Please enter a duration.
          </div>
        </BCol>
      </div>
      <div class="form-row form-group">
        <BFormCheckbox
          v-model="isGrantNow"
          switch
          name="isGrantNow"
        >
          Grant now
        </BFormCheckbox>
        <div
          v-if="!scheduledTimestampIsValid"
          class="invalid-feedback d-block"
        >
          The chosen date and time need to be valid and in the future.
        </div>
      </div>
      <div
        v-if="!isGrantNow"
        id="scheduledFirefighterPicker"
        class="form-row form-group"
      >
        <BCol>
          <p
            id="time-zone"
            class="text-muted"
          >
            Time zone: {{ timeZone }}
          </p>
        </BCol>

        <BCol>
          <BFormInput
            id="datePicker"
            v-model="requestDate"
            type="date"
            name="requestDate"
          />
          <div
            v-if="!requestDateIsValid"
            class="invalid-feedback d-block"
          >
            Please enter a valid date
          </div>
        </BCol>
        <BCol>
          <BInputGroup class="mb-3">
            <BFormInput
              id="scheduled-time"
              v-model="requestTime"
              type="text"
              placeholder="HH:mm"
            />
            <div
              v-if="!requestTimeIsValid"
              class="invalid-feedback d-block"
            >
              Please enter a valid time (HH:mm)
            </div>
          </BInputGroup>
        </BCol>
      </div>
      <div class="form-row form-group mb-0">
        <BCol>
          <AsyncButton
            id="requestFireFighterBtn"
            class="mt-2"
            :name="`Request Firefighter Access`"
            initial-icon="fire-alt"
            :disabled="!formIsValid"
            :fn="sendRequest"
            @status="updateStatus"
          />
        </BCol>
      </div>
    </BModal>
  </div>
</template>

<script setup lang="ts">
import axios from 'axios';
import {
  computed, PropType, reactive, Ref, ref,
} from 'vue';
import {
  BModal, BButton, BFormTextarea, BFormCheckbox,
  BInputGroup, BFormInput, BCol,
} from 'bootstrap-vue-next';
import { watchEffect } from '@vue/runtime-dom';
import * as moment from 'moment';
import AsyncButton from '@/components/AsyncButton.vue';
import DropDownListComponent from '@/components/DropDownListComponent.vue';
import { getKeycloakRequestConfig } from '@/utils/http';
import { SelectOptionsDropDown, TenantInformation } from '@/models/tenant';
import Validator from '@/utils/validator';
import { userStore } from '@/store/modules/user';
import { AsyncButtonStatus } from '@/models/asyncButton';
import { useAsyncButton } from '@/composable/apiStates';
import { BackendError } from '@/models/errors';
import { createDuration } from '@/composable/durationSelector';
import { useTenantRoles } from '@/composable/useTenantRoles';
import { FirefighterAccessRequestRequest } from '../models/firefighter';
/**
 * This Component represents a button and form in modal to request firefighter
 * access on the tenant detail page.
 */

const props = defineProps({
  tenantName: {
    type: String,
    default() {
      return '';
    },
  },
  tenantInfo: {
    type: Object as PropType<TenantInformation>,
    required: true,
  },
  successfulEvent: {
    type: Function,
    required: true,
  },
});

const emit = defineEmits(['successfulEvent']);

const status = useAsyncButton();

const isGrantNow = ref(true);

const requestDuration = ref(SelectOptionsDropDown.DURATION);

const requestReason = ref(null);

const requestDate = ref(null);

const requestTime = ref('08:00');

const user = userStore();

const requestRole = ref(SelectOptionsDropDown.ROLE);

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';

const modalRef = ref('') as unknown as Ref<BModal>;

const modalId = computed(() => `requestFirefighter_${props.tenantName}`);

const { durationOptions } = createDuration();

const { roleOptions } = useTenantRoles(props.tenantInfo, true);

const error: BackendError = reactive({
  type: null,
  message: null,
});

function preselectRequestValues() {
  if (props.tenantInfo.roles.length === 1) {
    requestRole.value = props.tenantInfo.roles[0].technicalName;
  }
}

function showModal() {
  // populates the timeZone when the modal is visible
  preselectRequestValues();
  modalRef.value.show();
}

function hideModal() {
  requestRole.value = null;
  requestReason.value = null;
  requestDuration.value = null;
  requestDate.value = null;
  requestTime.value = null;
  modalRef.value.hide();
}

const updateDuration = (selectedDuration: string) => {
  requestDuration.value = selectedDuration;
};

const updateRole = (selectedRole: string) => {
  requestRole.value = selectedRole;
};
const updateStatus = (statusCode: { status: AsyncButtonStatus, message: string }) => {
  status.status = statusCode.status;
  status.message = statusCode.message;
};

const requestStartAt = computed(() => {
  if (isGrantNow.value) {
    return null;
  }
  const parsedDate = moment(`${requestDate.value} ${requestTime.value}:00`, 'YYYY-MM-DD HH:mm:ss');
  return parsedDate.toISOString();
});

async function sendRequest() {
  const firefighterAccessRequest: FirefighterAccessRequestRequest = {
    role: requestRole.value,
    reason: requestReason.value,
    startNow: isGrantNow.value,
    startAt: requestStartAt.value,
    timeZone,
    duration: Number(requestDuration.value),
  };

  try {
    const response = await axios
      .post(
        `firefighter/requests/${props.tenantName}/users/${user.id}`,
        firefighterAccessRequest,
        getKeycloakRequestConfig(user.xsrfToken),
      );
    if (props.successfulEvent) {
      props.successfulEvent(response.status);
      emit('successfulEvent', response.status);
      hideModal();
    }
  } catch (err) {
    console.log('error', err);
    if (err.response && err.response.data) {
      error.message = err.response.data.message;
      emit('error', error.message);
    }
    throw error;
  }
}

const requestDurationIsValid = computed(() => {
  if (requestDuration.value != null) {
    return requestDuration.value > 0;
  }
  return null;
});

const requestReasonIsValid = computed(() => {
  if (requestReason.value != null) {
    return requestReason.value.trim().length > 0;
  }
  return null;
});

const requestDateIsValid = computed(() => {
  if (isGrantNow.value) {
    return true;
  }

  return Validator.isDateIsValid(requestDate.value);
});

const scheduledTimestampIsValid = computed(() => {
  if (isGrantNow.value) {
    return true;
  }
  return Validator.isTimestampValid(requestDate.value, requestTime.value);
});

const requestTimeIsValid = computed(() => {
  if (isGrantNow.value) {
    return true;
  }
  return Validator.isTimeIsValid(requestTime.value);
});

const requestRoleIsValid = computed(() => {
  if (requestRole.value !== SelectOptionsDropDown.ROLE) {
    return true;
  }
  return null;
});

const formIsValid = computed(() => requestDurationIsValid.value
    && requestReasonIsValid
    && requestRoleIsValid
    && (isGrantNow.value
      // eslint-disable-next-line vue/max-len
      || (requestDateIsValid.value && requestTimeIsValid.value && scheduledTimestampIsValid.value)));

watchEffect(() => {
  // Remove seconds from request time on timepicker component change
  if (requestTime.value !== null) {
    requestTime.value = requestTime.value.split(':').slice(0, 2).join(':');
  }
});

</script>
