<template>
  <footer class="footer">
    <BNavbar
      variant="dark"
      fixed="bottom"
      class="text-light px-3"
    >
      <span>version: {{ version }}</span>
    </BNavbar>
  </footer>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { BNavbar } from 'bootstrap-vue-next';
import app from '@/store/modules/app';

const appVersion = app();
const version = computed(() => appVersion.getVersion);

</script>

<style scoped>
footer {
  margin-top: 40px;
}
</style>
