import { computed } from 'vue';
import { DropDownList, SelectOptionsDropDown } from '@/models/tenant';

export function createDuration() {
  const durationOptions = computed(() => {
    const result: DropDownList[] = [];

    for (let i = 1; i <= 24; i += 1) {
      result.push({
        item: `${i}h`,
        title: `${i}`,
        disabled: false,
      });
    }

    result.unshift({
      item: SelectOptionsDropDown.DURATION,
      title: SelectOptionsDropDown.DURATION,
      disabled: true,
    });
    return result;
  });

  return {
    durationOptions,
  };
}
