// store/user.ts
import { defineStore } from 'pinia';
import * as qs from 'query-string';
import Cookies from 'js-cookie';
import router from '@/router';
import Config from '@/utils/config';
import { usePermissions } from './usePermissions';

interface UserState {
  id: string | null;
  xsrfToken: string | null;
}

export const userStore = defineStore('user', {
  state: (): UserState => ({
    id: null,
    xsrfToken: null,
  }),
  getters: {
    loggedIn: (state) => state.id !== null,
  },
  actions: {
    logout() {
      const domain = Config.PORTAL_DOMAIN;

      // Replace the following lines with the equivalent Pinia logic for cookie management
      Cookies.remove('userId', { domain, path: '/' });
      Cookies.remove('XSRF-Token', { domain, path: '/' });

      // Not sure if this works
      this.id = null;
      this.xsrfToken = null;

      return { id: this.id, xsrfToken: this.xsrfToken };
    },
    async login({ id, xsrfToken }: {id: string, xsrfToken: string}) {
      this.id = id;
      this.xsrfToken = xsrfToken;

      usePermissions().fetchPermissions();
    },

    async redirectToLogin() {
      const callbackPath = router.currentRoute.value.fullPath.replace(`/${Config.UNAUTHORIZED_PATH_PREFIX}`, '');
      const queryParams: string = qs.stringify({
        client_id: Config.BIFROST_CLIENT_ID,
        response_type: 'id_token',
        redirect_uri: Config.REDIRECT_URI,
        scope: 'openid email profile',
        response_mode: 'form_post',

        state: `${Config.PORTAL_REDIRECT_URI}?go=${encodeURIComponent(callbackPath)}`,
        nonce: '678910',
      });

      window.location.href = `${Config.BIFROST_URL}/auth/realms/digitalunit/protocol/openid-connect/auth?${queryParams}`;
    },
  },
});
