import {
  RouteLocationNormalized, RouteRecordRaw, createRouter, createWebHistory,
} from 'vue-router';
import { userStore } from '@/store/modules/user';
import { tryLogin } from './utils/login';
import Config from './utils/config';
import Home from './views/HomeComponent.vue';
import MyPermissions from './views/MyPermissions.vue';
import NotLoggedIn from './views/NotLoggedIn.vue';
import OAuthCallbackHandler from './views/OAuthCallback.vue';
import Profile from './views/ProfileComponent.vue';
import TenantDetails from './views/TenantDetails.vue';
import TenantReports from './views/TenantReports.vue';
import MyTenants from './views/MyTenants.vue';
import FirefighterAccessDetails from './views/FirefighterAccessDetails.vue';
import TenantSettings from './views/TenantSettings.vue';
import BulkPermissionManagement from './views/BulkPermissionManagement.vue';

const ifAuthenticated = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: any) => {
  const user = userStore();
  if (!user.loggedIn) {
    tryLogin();
  }

  if (user.loggedIn) {
    next();
    return;
  }

  next(`${Config.UNAUTHORIZED_PATH_PREFIX}${to.fullPath}`);
};
const ifNotAuthenticated = (to: RouteLocationNormalized, _: RouteLocationNormalized, next: any) => {
  const user = userStore();
  if (!user.loggedIn) {
    tryLogin();
  }

  if (!user.loggedIn) {
    next();
    return;
  }

  if (to.params) {
    next(`/${to.fullPath.replace(`${Config.UNAUTHORIZED_PATH_PREFIX}/`, '')}`);
  } else {
    next('/');
  }
};

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: Home,
  },
  {
    path: '/permissions',
    component: MyPermissions,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/login/callback',
    component: OAuthCallbackHandler,
  },
  {
    path: `/${Config.UNAUTHORIZED_PATH_PREFIX}/:rest*`,
    component: NotLoggedIn,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/profile',
    component: Profile,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/permissions',
    component: MyPermissions,
    beforeEnter: ifAuthenticated,
  },
  {
    name: 'myTenants',
    path: '/tenants',
    component: MyTenants,
    beforeEnter: ifAuthenticated,
  },
  {
    name: 'tenantDetails',
    path: '/tenants/:tenantName',
    component: TenantDetails,
    beforeEnter: ifAuthenticated,
  },
  {
    name: 'tenantReports',
    path: '/tenants/:tenantName/reports',
    component: TenantReports,
    beforeEnter: ifAuthenticated,
  },
  {
    name: 'tenantSettings',
    path: '/tenants/:tenantName/settings',
    component: TenantSettings,
    beforeEnter: ifAuthenticated,
  },
  {
    name: 'firefighterAccessDetails',
    path: '/firefighters/:firefighterAccessId',
    component: FirefighterAccessDetails,
    beforeEnter: ifAuthenticated,
  },
  {
    name: 'mutliTenantActions',
    path: '/bulk-permission-management',
    component: BulkPermissionManagement,
    beforeEnter: ifAuthenticated,
  },
];

export default createRouter({
  routes,
  // TODO: question: do we need a base history here? VUE_APP_BASE_URL maybe?
  // eslint-disable-next-line max-len
  history: createWebHistory(),
  // linkActiveClass: 'active',
});
