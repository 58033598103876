<template>
  <BTable
    :items="items"
    :fields="fields"
    small
    show-empty
    empty-text="No permissions to assign. Add users and roles."
  />
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { BTable } from 'bootstrap-vue-next';
import { useBulkPermissionManagement } from '@/store/modules/useBulkPermissionManagement';

const store = useBulkPermissionManagement();
const { permissions } = storeToRefs(store);

const items = computed(() => permissions.value.map((permission) => ({
  user: permission.user.email,
  tenant: permission.tenant.name,
  role: permission.role.name,
})));

const fields = [
  { key: 'user', sortable: true },
  { key: 'tenant', sortable: true },
  { key: 'role', sortable: true }];
</script>

<style lang="scss">
  .b-table-empty-slot {
    text-align: center !important;
  }
</style>
