import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderComponent = _resolveComponent("HeaderComponent")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_BCol = _resolveComponent("BCol")!
  const _component_FooterComponent = _resolveComponent("FooterComponent")!
  const _component_BRow = _resolveComponent("BRow")!
  const _component_BToastOrchestrator = _resolveComponent("BToastOrchestrator")!
  const _component_BContainer = _resolveComponent("BContainer")!

  return (_openBlock(), _createBlock(_component_BContainer, {
    id: "app",
    fluid: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_BRow, null, {
        default: _withCtx(() => [
          _createVNode(_component_BCol, { cols: "12" }, {
            default: _withCtx(() => [
              _createVNode(_component_HeaderComponent),
              _createVNode(_component_router_view, { class: "mt-md-5 p-0" })
            ]),
            _: 1
          }),
          _createVNode(_component_FooterComponent)
        ]),
        _: 1
      }),
      _createVNode(_component_BToastOrchestrator)
    ]),
    _: 1
  }))
}