import axios from 'axios';
import { TenantInformation } from '@/models/tenant';
import { getKeycloakRequestConfig } from '@/utils/http';
import { userStore } from '@/store/modules/user';

export async function fetchTenant(tenantName: string): Promise<TenantInformation> {
  const user = userStore();

  const response = await axios.get<TenantInformation>(
    `/tenants/${tenantName}`,
    getKeycloakRequestConfig(user.xsrfToken),
  );

  return response.data as TenantInformation;
}
