<template>
  <Bubblewrap
    id="sensitiveEvents"
    :info="sensitiveEventsCall"
  >
    <template #loaded>
      <div class="container mt-3">
        <div class="row">
          <h3>
            Sensitive Actions
          </h3>
          <div
            id="emptySensitiveEvents"
            class="mt-2 container"
          >
            <p id="sensitiveMessageText">
              {{ sensitiveEventUserMessageInfo }}
            </p>
          </div>
        </div>
      </div>
      <div v-if="sensitiveEvents.length > 0">
        <div class="pt-15">
          <div class="table-responsive">
            <table
              id="sensitiveEventsTable"
              class="table align-items-center table-bordered table-fluid"
            >
              <thead>
                <tr>
                  <th class="text-secondary text-xxs font-weight-bolder opacity-7">
                    Sensitive Action
                  </th>
                  <th class="text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                    Occurrences
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in sensitiveEventsPerList"
                  :key="item.eventName"
                >
                  <td>
                    <p class="text-xs font-weight-bold mb-0">
                      {{ item.eventName }}
                    </p>
                  </td>
                  <td>
                    <p class="text-xs text-secondary mb-0">
                      {{ item.count }}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div
          v-if="sensitiveEvents.length > sensitiveEventsPerPage"
          class="flex"
        >
          <b-pagination
            v-model="sensitiveEventsCurrentPage"
            class="justify-content-start"
            :total-rows="rows"
            :per-page="sensitiveEventsPerPage"
            aria-controls="sensitiveEventsTable"
            first-number
            last-number
          />
        </div>
      </div>
      <div />
    </template>
    <!-- Template to show while the app receives data from API-->
    <template #placeholder>
      <div class="content-loader">
        <div class="container">
          <div class="row">
            <h3>
              Sensitive Actions
            </h3>
          </div>
        </div>
        <div class="pt-15">
          <div class="table-responsive">
            <table class="table align-items-center table-bordered table-fluid">
              <thead>
                <tr>
                  <th class="text-secondary text-xxs font-weight-bolder opacity-7">
                    <ContentLoader height="32px" />
                  </th>
                  <th class="text-secondary text-xxs font-weight-bolder opacity-7 ps-2">
                    <ContentLoader height="32px" />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in sensitiveEventsPerList"
                  :key="item.eventName"
                >
                  <td>
                    <p class="text-xs font-weight-bold mb-0">
                      <ContentLoader height="52px" />
                    </p>
                  </td>
                  <td>
                    <p class="text-xs text-secondary mb-0">
                      <ContentLoader height="52px" />
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div />
    </template>
  </Bubblewrap>
</template>

<script setup lang="ts">
import {
  computed, onMounted, reactive, ref,
} from 'vue';

import axios from 'axios';
import { getKeycloakRequestConfig } from '@/utils/http';
import { FirefighterSensitiveEvent, FirefighterStatus } from '@/models/firefighter';
import Bubblewrap from '@/components/BubblewrapComponent.vue';
import { BubblewrapInfo } from '@/models/bubblewrap';
import { userStore } from '@/store/modules/user';

// eslint-disable-next-line no-shadow
enum SensitiveEventMessage {
  NO_SENSITIVE_EVENTS = 'By following the AWS CIS Benchmark, no sensitive events were found within the firefighter access time frame.',
  REPORT_GATHERING_LOG = 'Sensitive Events could not be loaded because the status report is in progress. Once the status is in finished state we will display the sensitive events in case we found one.',
  NO_SENSITIVE_KEY = 'No sensitive action report is available for this firefighter request. Please check the after action report to see all performed actions.',
}

const props = defineProps({
  firefighterAccessId: {
    type: String,
    required: true,
  },
  status: {
    type: String,
    required: true,
  },
});

const sensitiveEventsPerPage = ref(3);

const sensitiveEventsCurrentPage = ref(1);

const sensitiveEventUserMessageInfo = ref(null);

const user = userStore();

const sensitiveEvents: FirefighterSensitiveEvent[] = reactive([]);

const sensitiveEventsCall: BubblewrapInfo = reactive({
  error: null,
  loaded: false,
});

async function fetchSensitiveEvent() {
  // eslint-disable-next-line max-len
  if (
    props.status === FirefighterStatus.GATHERING_LOGS
      || props.status === FirefighterStatus.WAITING_FOR_LOGS
  ) {
    sensitiveEventsCall.loaded = true;
    sensitiveEventUserMessageInfo.value = SensitiveEventMessage.REPORT_GATHERING_LOG;
  } else {
    await axios
      .get<FirefighterSensitiveEvent[]>(
        `/firefighter/accesses/${props.firefighterAccessId}/sensitiveEventsReport`,
        getKeycloakRequestConfig(user.xsrfToken),
      )
      .then((response) => {
        if (response.status === 200) {
          sensitiveEventsCall.loaded = true;
          if (response.data.length > 0) {
            Object.assign(sensitiveEvents, response.data);
          } else {
            sensitiveEventUserMessageInfo.value = SensitiveEventMessage.NO_SENSITIVE_EVENTS;
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          sensitiveEventsCall.loaded = true;
          sensitiveEventUserMessageInfo.value = SensitiveEventMessage.NO_SENSITIVE_KEY;
        } else {
          sensitiveEventsCall.error = error;
        }
      });
  }
}

const sensitiveEventsPerList = computed(() => sensitiveEvents.slice(
  (sensitiveEventsCurrentPage.value - 1) * sensitiveEventsPerPage.value,
  sensitiveEventsCurrentPage.value * sensitiveEventsPerPage.value,
));

const rows = computed(() => sensitiveEvents.length);

onMounted(async () => {
  try {
    await fetchSensitiveEvent();
  } catch (e) {
    console.log(e);
    sensitiveEventsCall.error = new Error('Could not load firefighter sensitive action.');
  }
});

</script>

<style lang="scss" scoped></style>
