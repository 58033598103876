<template>
  <div
    id="settings"
    class="container"
  >
    <div class="row">
      <div class="col-offset-2 col-12">
        <BBreadcrumb>
          <BBreadcrumbItem :to="{ name: 'myTenants' }">
            My Tenants
          </BBreadcrumbItem>
          <BBreadcrumbItem
            :to="{ name: 'tenantDetails', params: { tenantName: route.params.tenantName } }"
          >
            {{ route.params.tenantName }}
          </BBreadcrumbItem>
          <BBreadcrumbItem active>
            Settings
          </BBreadcrumbItem>
        </BBreadcrumb>
        <h3 class="mt-4">
          Tenant Settings for {{ route.params.tenantName }}
        </h3>
        <p class="lead">
          On this page you can manage the settings of your tenant.
        </p>
        <Bubblewrap :info="apiCall">
          <template #placeholder>
            <ContentLoader
              height="18px"
            />
          </template>
          <template #loaded>
            <div
              v-if="successful"
              class="alert alert-success"
              role="alert"
            >
              Saved settings
            </div>
            <div
              v-if="status.status === AsyncButtonStatus.ERROR"
              class="alert alert-danger"
              role="alert"
            >
              Failed to save settings
            </div>

            <h4>Notification settings</h4>
            <p>
              If you want to have notifications about permission changes besides the approver,
              please add further email addresses.
            </p>
            <BFormGroup class="mb-2">
              <BFormTags
                v-if="settings"
                id="notificationEmails"
                v-model="settings.notificationEmails"
                :value="settings.notificationEmails"
                :tag-validator="emailValidator"
                placeholder="Enter email(s)"
                separator=" "
                invalid-tag-text="Invalid email address"
                duplicate-tag-text="Email already added"
                add-on-change
                input="settings.notificationEmails = $event"
                :tag-state="onTagState"
              />
            </BFormGroup>
            <AsyncButton
              id="saveTenantSettings"
              name="Save"
              initial-icon="save"
              :fn="saveSettings"
              :disabled="isFormValid"
              @status="updateStatus"
            />
          </template>
        </bubblewrap>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import axios from 'axios';
import {
  computed, ComputedRef, onMounted, reactive, ref,
} from 'vue';
import { useRoute } from 'vue-router';
import {
  BBreadcrumb, BBreadcrumbItem, BFormGroup, BFormTags,
} from 'bootstrap-vue-next';
import Bubblewrap from '@/components/BubblewrapComponent.vue';
import AsyncButton from '@/components/AsyncButton.vue';
import { getKeycloakRequestConfig } from '@/utils/http';
import { AsyncButtonStatus } from '@/models/asyncButton';
import { userStore } from '@/store/modules/user';
import { useApiCall, useAsyncButton } from '@/composable/apiStates';
import { TenantSettingsRepresentation } from '../models/settings';
import Validator from '../utils/validator';

const route = useRoute();
const user = userStore();
const settings: TenantSettingsRepresentation = reactive({ notificationEmails: [] });
const tagStatus = ref(false);
const successful = ref(false);
const isFormValid: ComputedRef<boolean> = computed(() => !tagStatus.value);
const apiCall = useApiCall();
const status = useAsyncButton();

function onTagState(_valid: string[], invalid: string[], duplicate: string[]) {
  tagStatus.value = invalid.length === 0 && duplicate.length === 0;
}

function emailValidator(email: string): boolean {
  return Validator.isValidEmail(email);
}

function updateStatus(updatedStatus: AsyncButtonStatus, message: string) {
  status.status = updatedStatus;
  status.message = message;
}

async function fetchSettings(): Promise<TenantSettingsRepresentation> {
  const response = await axios.get<TenantSettingsRepresentation>(
    `/tenants/${route.params.tenantName}/settings`,
    getKeycloakRequestConfig(user.xsrfToken),
  );
  // Add "notificationEmails" field to response
  if (!response.data.notificationEmails) {
    response.data.notificationEmails = [];
  }
  return response.data as TenantSettingsRepresentation;
}

async function saveSettings() {
  const response = await axios.put<TenantSettingsRepresentation>(
    `/tenants/${route.params.tenantName}/settings`,
    settings,
    getKeycloakRequestConfig(user.xsrfToken),
  );
  successful.value = response.status === 200;
}

onMounted(async () => {
  try {
    const responseData = await fetchSettings();
    Object.assign(settings, responseData);
    console.log(settings.notificationEmails);
  } catch (e) {
    console.log(e);
    apiCall.error = new Error('Could not load settings.');
  }
  apiCall.loaded = true;
  tagStatus.value = true;
});

</script>
