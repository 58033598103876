<template>
  <div
    id="profile"
    class="container"
  >
    <BRow>
      <BCol class="col-offset-2">
        <h3 class="mt-4">
          My Tenants
        </h3>

        <p class="lead">
          You can see tenants that you have permissions for in the list below. You are also able to
          look up more information for a tenant here.
        </p>
        <Bubblewrap :info="apiCall">
          <template #placeholder>
            <ContentLoader text="Loading tenants..." min-height="10rem" />
          </template>

          <template #loaded>
            <TenantList :tenants="tenants" />
          </template>
        </Bubblewrap>
      </BCol>
    </BRow>
  </div>
</template>

<script setup lang="ts">
import axios from 'axios';
import { onBeforeMount, reactive } from 'vue';
import { BCol, BRow } from 'bootstrap-vue-next';
import Bubblewrap from '@/components/BubblewrapComponent.vue';
import TenantList from '@/components/TenantList.vue';
import { useApiCall } from '@/composable/apiStates';
import { TenantRepresentation } from '@/models/keycloak';
import { getKeycloakRequestConfig } from '@/utils/http';
import { userStore } from '@/store/modules/user';
import ContentLoader from '@/components/ContentLoader.vue';

const tenants: TenantRepresentation[] = reactive([]);
const user = userStore();
const apiCall = useApiCall();

/**
     * fetchTenants loads the logged-in users permissions from the API
     */
async function fetchTenants(): Promise<TenantRepresentation[]> {
  return axios
    .get<TenantRepresentation[]>(
      `/users/${user.id}/tenants`,
      getKeycloakRequestConfig(user.xsrfToken),
    )
    .then((response) => response.data as TenantRepresentation[]);
}

onBeforeMount(async () => {
  let data: TenantRepresentation[];
  try {
    data = await fetchTenants();
    Object.assign(tenants, data);
    apiCall.loaded = true;
  } catch (err) {
    apiCall.error = err;
  }
});

</script>
