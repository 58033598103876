<template>
  <div class="container">
    <div class="row">
      <div class="col-offset-2 col-12">
        <BBreadcrumb>
          <BBreadcrumbItem :to="{ name: 'myTenants' }">
            My Tenants
          </BBreadcrumbItem>
          <BBreadcrumbItem
            :to="{ name: 'tenantDetails', params: { tenantName: route.params.tenantName } }"
          >
            {{ route.params.tenantName }}
          </BBreadcrumbItem>
          <BBreadcrumbItem active>
            Reports
          </BBreadcrumbItem>
        </BBreadcrumb>

        <h3 class="mt-4">
          Tenant Reports for {{ route.params.tenantName }}
        </h3>

        <p
          class="lead"
        >
          This page contains your tenant reports for example Firefighter access history.
        </p>

        <AsyncTable
          :fn="() => loadFirefighterTable()"
          headline="Firefighter Access History"
        >
          <div
            v-if="firefighterAccesses.length === 0"
            class="alert alert-light"
          >
            There is no temporary production access at the moment.
          </div>
          <BTable
            v-if="firefighterAccesses.length > 0"
            id="firefighters"
            striped
            hover
            :items="firefighterAccesses"
            :fields="firefighterFieldsOriginal"
            :sort-by="sortBy"
            sort-desc="true"
            primary-key="id"
          >
            <template #cell(fullName)="data">
              <a :href="`mailto:${data.item.user.email}`">
                {{ data.item.user.firstName }} {{ data.item.user.lastName }}
              </a>
            </template>
            <template #cell(grantedByFullName)="data">
              <a :href="`mailto:${data.item.grantedBy.email}`">
                {{ data.item.grantedBy.firstName }} {{ data.item.grantedBy.lastName }}
              </a>
            </template>
            <template #cell(startAt)="data">
              {{ $filters.formatDate(data.item.startAt) }}
            </template>
            <template #cell(revokeAt)="data">
              <!-- eslint-disable-next-line -->
              {{ $filters.formatDate((data.item.canceledAt === undefined ? data.item.revokeAt : data.item.canceledAt )) }}
            </template>
            <template #cell(reason)="data">
              <font-awesome-icon
                :id="`firefighter_${data.item.id}`"
                v-b-tooltip.html="data.item.reason"
                class="ff-button-icon"
                icon="info-circle"
              />
            </template>
            <template #cell(details)="data">
              <BButton
                size="sm"
                :to="{
                  name: 'firefighterAccessDetails',
                  params: { firefighterAccessId: data.item.id },
                }"
              >
                Show Details
              </BButton>
            </template>
          </BTable>
        </AsyncTable>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router';
import {
  BTable, BBreadcrumb, BBreadcrumbItem, BButton,
  TableField, BTableSortBy,
} from 'bootstrap-vue-next';
import Axios from 'axios';
import AsyncTable from '@/components/AsyncTable.vue';
import { userStore } from '@/store/modules/user';
import { FirefighterAccessRepresentation } from '../models/firefighter';
import { getKeycloakRequestConfig } from '../utils/http';

const user = userStore();

const firefighterFieldsOriginal: TableField<FirefighterAccessRepresentation>[] = [
  {
    key: 'fullName',
    label: 'Name',
    sortable: true,
    formatter: (_value: any, _key: string, item: any) => `${item.user.firstName} ${item.user.lastName}`,
  },
  {
    key: 'role',
    label: 'Role',
    sortable: true,
  },
  {
    key: 'grantedByFullName',
    label: 'Granted by',
    sortable: true,
    formatter: (_value: any, _key: string, item: any) => `${item.grantedBy.firstName} ${item.grantedBy.lastName}`,
  },
  {
    key: 'startAt',
    label: 'Grant Time',
    sortable: true,
  },
  {
    key: 'revokeAt',
    label: 'Revoke Time',
    sortable: true,
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'reason',
    label: 'Reason',
    class: 'text-center',
  },
  {
    key: 'details',
    label: 'Show Details',
  },
];

const sortBy: BTableSortBy[] = [{
  order: 'desc',
  key: 'startAt',
}];

const route = useRoute();

let firefighterAccesses: Array<FirefighterAccessRepresentation> = [];

async function loadFirefighterTable() {
  const response = await Axios.get<FirefighterAccessRepresentation[]>(
    `/tenants/${route.params.tenantName}/firefighters`,
    getKeycloakRequestConfig(user.xsrfToken),
  );

  firefighterAccesses = response.data.map((access) => ({
    ...access,
    startAt: new Date(access.startAt),
    revokeAt: new Date(access.revokeAt),
    canceledAt: access.canceledAt === undefined ? undefined : new Date(access.canceledAt),
  }));
}
</script>
