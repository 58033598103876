<template>
  <div id="addUserRole">
    <BButton
      id="showAddMember"
      v-b-toggle="'toggle-Member'"
      variant="primary"
    >
      Add Member
    </BButton>
    <div class="row">
      <div class="col-offset-2 col-12">
        <BCollapse
          id="toggle-Member"
          class="mt-2"
        >
          <div class="card card-body">
            <div>
              <div
                class="form-group"
                :onsubmit="formIsValid"
              >
                <div class="row-cols-md-8">
                  <autocomplete
                    ref="emailAutocomplete"
                    base-class="uc-autocomplete"
                    placeholder="Email address"
                    :search="onUsernameChange"
                    :get-result-value="(u: Suggestion) => u.username"
                    :input="username"
                    @change="onUpdateEmail"
                    @submit="onSelectEmail"
                  />
                  <div
                    v-if="typeaheadProcess"
                    style="position: absolute; top: 25px; right: 36px; z-index: 1000"
                  >
                    <BSpinner
                      v-if="typeaheadProcess"
                      label="Loading..."
                      small
                    />
                  </div>
                  <div
                    v-if="!usernameIsValid"
                    class="invalid-feedback d-block"
                  >
                    Please enter a valid email address.
                  </div>
                  <div
                    v-if="usernameIsValid && userNotFound"
                    id="newUserNotFoundError"
                    class="invalid-feedback d-block"
                  >
                    We couldn't find any users with this email address.
                  </div>
                </div>
                <br>
                <div class="row-cols-md-8">
                  <DropDownListComponent
                    id="roleSelector"
                    v-model="role"
                    :default-option="SelectOptionsDropDown.ROLE"
                    :options="roleOptions"
                    @select-value="updateRole"
                  />
                  <div
                    v-if="!roleIsValid"
                    class="invalid-feedback d-block"
                  >
                    You need to choose a role that should be granted to the user.
                  </div>
                </div>
              </div>
              <AsyncButton
                id="addMember"
                class="mt-2"
                :name="`Add Member`"
                initial-icon="user-plus"
                :confirm-modal="`You are about to grant access to ${username}
                  for your tenant ${tenantName} with the role ${role}.
                  Do you want to continue?`"
                :disabled="!showAddButton"
                :fn="() => sendForm()"
                @status="handleUpdateStatus"
                @error="() => handleRemovalError()"
              />
            </div>
          </div>
        </BCollapse>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import axios from 'axios';
import {
  computed, PropType, reactive, watch,
} from 'vue';
import { BButton, BCollapse, BSpinner } from 'bootstrap-vue-next';
import { useRoute } from 'vue-router';
import Autocomplete from '@trevoreyre/autocomplete-vue';
import { ref } from '@vue/runtime-dom';
import AsyncButton from '@/components/AsyncButton.vue';
import DropDownListComponent from '@/components/DropDownListComponent.vue';
import { getKeycloakRequestConfig } from '@/utils/http';
import { Permission } from '@/models/keycloak';
import { TenantInformation, SelectOptionsDropDown } from '@/models/tenant';
import { BackendError } from '@/models/errors';
import { userStore } from '@/store/modules/user';
import { Suggestion } from '@/models/typeahead';
import useTenantComponent from '@/composable/tenantComponent';
import { AsyncButtonStatus } from '@/models/asyncButton';
import { useAsyncButton } from '@/composable/apiStates';
import { useTenantRoles } from '@/composable/useTenantRoles';
import Utils from '@/utils/utils';

const props = defineProps({
  tenantName: {
    type: String,
    required: true,
  },
  tenantInfo: {
    type: Object as PropType<TenantInformation>,
    required: true,
  },
  successfulEvent: {
    type: Function,
    required: true,
  },
  users: {
    type: Array as PropType<Permission[]>,
    required: true,
  },
});

const emits = defineEmits(['success', 'error', 'successfulEvent']);
const route = useRoute();
const user = userStore();
const status = useAsyncButton();
let { userTypeaheadSuggestions } = useTenantComponent();
const { roleOptions } = useTenantRoles(props.tenantInfo, false);
const {
  // eslint-disable-next-line max-len
  username, typeaheadProcess, usernameIsValid, getTypeaheadUser,
  role, roleIsValid, showUserMissing,
} = useTenantComponent();

const errorResponse: BackendError = reactive({
  type: null,
  message: null,
});

const updateRole = (selectedRole: string) => {
  role.value = selectedRole;
  status.status = AsyncButtonStatus.INITIAL;
  status.message = null;
  errorResponse.type = null;
  errorResponse.message = null;
};

const userNotFound = ref(false);

// Debounced check for user existence
const debouncedCheckUser = Utils.debounce(async (input: string) => {
  if (input.length >= 3) {
    userTypeaheadSuggestions = await getTypeaheadUser(input);
    const found = showUserMissing(userTypeaheadSuggestions);
    userNotFound.value = !found;
  } else {
    userNotFound.value = false;
  }
}, 100);

const hasRole = computed(() => {
  if (props.users && role) {
    return props.users.find(
      (u) => u.username === username.value && u.role === role.value && u.environment !== 'prod',
    );
  }
  return null;
});

const emailAutocomplete = ref(null);

// eslint-disable-next-line max-len
const formIsValid = computed(() => usernameIsValid.value && !hasRole.value && roleIsValid.value);
const showAddButton = computed(() => formIsValid.value);

async function onUpdateEmail(input: any) {
  userNotFound.value = false;
  if (input === null) {
    username.value = null;
    userNotFound.value = true;
  } else {
    username.value = input.target.value;
    debouncedCheckUser(username.value);
  }
}

async function onUsernameChange(input: string): Promise<Suggestion[]> {
  if (input.length < 3) {
    username.value = null;
    return [];
  }
  username.value = input;
  userTypeaheadSuggestions = await getTypeaheadUser(input);
  return userTypeaheadSuggestions;
}

async function onSelectEmail(input: Suggestion) {
  username.value = input.username;
}

const handleUpdateStatus = (statusBtn: AsyncButtonStatus, message: string) => {
  status.status = statusBtn;
  status.message = message;
  if (status.status === AsyncButtonStatus.INITIAL) {
    role.value = SelectOptionsDropDown.ROLE;
    username.value = '';
    emailAutocomplete.value.setValue({ name: '' });
  }
};

watch(role, () => {
  status.status = AsyncButtonStatus.INITIAL;
  status.message = null;
  errorResponse.type = null;
  errorResponse.message = null;
});

function handleRemovalError() {
  errorResponse.message = null;
  errorResponse.type = null;
}

async function sendForm() {
  const suggestion = userTypeaheadSuggestions.find((u) => u.username === username.value);
  const url = `/tenants/${route.params.tenantName}/users/${suggestion.userId}/roles/${role.value}`;
  const body = {};

  try {
    const response = await axios.put<Permission>(
      url,
      body,
      getKeycloakRequestConfig(user.xsrfToken),
    );
    if (props.successfulEvent) {
      console.log('successful', props.successfulEvent);
      props.successfulEvent(response.data);
    }
  } catch (err) {
    console.log('error', err);
    if (err.response && err.response.data) {
      errorResponse.message = err.response.data.message;
      emits('error', errorResponse.message);
    }
    throw errorResponse;
  }
}
</script>
