import { reactive } from 'vue';
import { AsyncButtonStatus } from '@/models/asyncButton';
import { BubblewrapInfo } from '@/models/bubblewrap';

export function useApiCall() {
  const apiCall: BubblewrapInfo = reactive({ error: null, loaded: false });

  return apiCall;
}

export function useAsyncButton() {
  const status = reactive({ status: AsyncButtonStatus.INITIAL, message: '' });

  return status;
}
