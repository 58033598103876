export interface FirefighterUser {
  id: string;
  firstName?: string;
  lastName?: string;
  email?: string;
}

// eslint-disable-next-line no-shadow
export enum FirefighterStatus {
  SCHEDULED = 'scheduled',
  CANCELLED = 'cancelled',
  PENDING = 'pending',
  ACTIVE = 'active',
  WAITING_FOR_LOGS = 'waiting_for_logs',
  GATHERING_LOGS = 'gathering_logs',
  FINISHED = 'finished',
  ERROR = 'error',
}

export interface FirefighterAccessRepresentation {
  id: string;
  user: FirefighterUser;
  grantedBy: FirefighterUser;
  role: string;
  tenant: string;
  reason: string;
  requestedAt: Date;
  startAt: Date;
  revokeAt: Date;
  status: FirefighterStatus;
  canceledAt: Date;
}

export interface FirefighterAccessRequestRequest {
  role: string;
  reason: string;
  startNow: boolean;
  startAt: string;
  timeZone: string;
  duration: number;
}

export interface FirefighterSensitiveEvent {
  eventName: string;
  count: number;
}

export interface FirefighterSensitiveEventReport {
  fireFighterAccessId: string;
  sensitiveEvents: FirefighterSensitiveEvent[];
}
