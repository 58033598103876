// import * as cookies from 'js-cookie';
import Cookies from 'js-cookie';
import { userStore } from '@/store/modules/user';

let checkSessionInterval = null;

/**
 * Attempt to log the user in. If the login is successful, start a timer
 * to check periodically for expiry of the session
 */
export async function tryLogin() {
  // Attempt to retrieve userId and xsrfToken cookies
  const id = Cookies.get('userId');
  const xsrfToken = Cookies.get('XSRF-Token');
  const user = userStore();
  if (id && xsrfToken) {
    await user.login({
      id,
      xsrfToken,
    });

    if (!checkSessionInterval) {
      checkSessionInterval = setInterval(() => {
        if (!user.loggedIn) {
          clearInterval(checkSessionInterval);
          checkSessionInterval = null;
          return;
        }

        if (!Cookies.get('userId')) {
          user.redirectToLogin();

          clearInterval(checkSessionInterval);
          checkSessionInterval = null;
        }
      }, 5000);
    }
  }
}
