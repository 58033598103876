<template>
  <div>
    <slot name="headline">
      <h4 class="mt-4">
        {{ headline }}
      </h4>
    </slot>
    <BubblewrapComponent :info="apiCall">
      <template #placeholder>
        <table class="table table-striped">
          <thead>
            <tr>
              <th class="align-middle">
                <ContentLoader
                  :width="`${atWidth}px`"
                  :height="`${atHeight}px`"
                />
              </th>
            </tr>
          </thead>
          <tbody class>
            <tr
              v-for="n in defaultLines"
              :key="n"
              class
            >
              <td>
                <ContentLoader
                  :width="`${atWidth}px`"
                  :height="`${atHeight}px`"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </template>
      <template #loaded>
        <slot />
      </template>
    </BubblewrapComponent>
  </div>
</template>

<script setup lang="ts">
import {
  onMounted, reactive, defineProps,
} from 'vue';
import BubblewrapComponent from '@/components/BubblewrapComponent.vue';

const props = defineProps({
  fn: {
    type: Function,
    required: true,
  },
  headline: {
    type: String,
    required: true,
  },
  atWidth: {
    default: 1000,
    type: Number,
  },
  atHeight: {
    default: 20,
    type: Number,
  },
  defaultLines: {
    type: Number,
    default: 3,
  },
});

const apiCall = reactive({
  loaded: false,
  error: null,
});

onMounted(async () => {
  try {
    await props.fn();
  } catch (e) {
    apiCall.error = e;
  }
  apiCall.loaded = true;
});

</script>
