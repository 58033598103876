export default class Config {
  public static REDIRECT_URI = process.env.VUE_APP_REDIRECT_URI;

  public static PORTAL_REDIRECT_URI = process.env.VUE_APP_PORTAL_REDIRECT_URI;

  public static BIFROST_URL = process.env.VUE_APP_BIFROST_URL;

  public static BIFROST_CLIENT_ID = process.env.VUE_APP_BIFROST_CLIENT_ID;

  public static PORTAL_API_URL = process.env.VUE_APP_PORTAL_API_URL;

  public static PORTAL_DOMAIN = process.env.VUE_APP_PORTAL_DOMAIN;

  public static UNAUTHORIZED_PATH_PREFIX = 'not-logged-in';
}
