<template>
  <BContainer
    id="app"
    fluid
  >
    <BRow>
      <BCol cols="12">
        <HeaderComponent />
        <router-view class="mt-md-5 p-0" />
      </BCol>
      <FooterComponent />
    </BRow>
    <BToastOrchestrator />
  </BContainer>
</template>

<script lang="ts">

import { onBeforeMount, defineComponent } from 'vue';
import {
  BContainer, BRow, BCol,
} from 'bootstrap-vue-next';
import FooterComponent from './components/FooterComponent.vue';
import HeaderComponent from './components/HeaderComponent.vue';
import { tryLogin } from './utils/login';

declare module 'vue' {
  interface ComponentCustomProperties {
    $cookies: any;
    $filters: any;
  }
}

export default defineComponent({
  name: 'App',
  components: {
    HeaderComponent, FooterComponent, BContainer, BRow, BCol,
  },
  setup() {
    onBeforeMount(() => {
      tryLogin();
    });
  },
});
</script>

<style lang="scss">
@import "style.module.scss";

// Bootstrap 5 removed all padding etc. for breadcrumbs
ol.breadcrumb {
  --bs-bg-opacity: 1;
  background-color: #e9ecef !important;
  padding: 1rem !important;
}
</style>
