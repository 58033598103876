<template>
  <div>
    <button
      id="logout"
      class="btn btn-outline-danger my-2 my-sm-0"
      type="button"
      @click="logout()"
    >
      Logout
    </button>
  </div>
</template>

<script setup lang="ts">

import { useRouter } from 'vue-router';
import { userStore } from '@/store/modules/user';

const router = useRouter();
const user = userStore();
function logout() {
  window.sessionStorage.removeItem('IDTOKEN');
  user.logout();
  router.push({ path: '/' });
}

</script>
