<template>
  <div>
    <p class="mb-0">
      This wizard will guide you through the process of adding
      users to multiple tenants at once.
    </p>
    <p />
    <p>
      Here are the steps:
    </p>
    <ol>
      <li>
        Select the users you want to onboard
      </li>
      <li>
        Select the roles per tenant you want to assign to all selected users
      </li>
      <li>
        Review new permissions
      </li>
      <li>
        Start the execution
      </li>
    </ol>
    <BCard>
      <BFormCheckbox
        :checked="store.skipIntro"
        switch
        @change="store.toggleSkipIntro"
      >
        Don't show this page again
      </BFormCheckbox>
    </BCard>
  </div>
</template>

<script setup lang="ts">
import { useBulkPermissionWizard } from '@/store/modules/useBulkPermissionWizard';
import { BCard, BFormCheckbox } from 'bootstrap-vue-next';

const store = useBulkPermissionWizard();
</script>
