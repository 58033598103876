<template>
  <BContainer id="profile">
    <BRow>
      <BCol>
        <h3 class="mt-4">
          My Permissions
        </h3>

        <p class="lead">
          You can see a list of the permissions assigned to you below.
        </p>

        <Bubblewrap :info="apiCall">
          <template #placeholder>
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>Group name</th>
                    <th>Environment</th>
                    <th>Role</th>
                    <th>
                      Certification expires in
                      <font-awesome-icon icon="question-circle" />
                    </th>
                  </tr>
                </thead>
                <tbody class>
                  <tr
                    v-for="n in 3"
                    :key="n"
                    class
                  >
                    <td
                      aria-colindex="1"
                      style="width: 100px;"
                    >
                      <ContentLoader
                        height="2px"
                        width="100px"
                      />
                    </td>
                    <td
                      aria-colindex="1"
                      style="width: 100px;"
                    >
                      <ContentLoader
                        height="2px"
                        width="100px"
                      />
                    </td>
                    <td
                      aria-colindex="1"
                      style="width: 100px;"
                    >
                      <ContentLoader
                        height="2px"
                        width="100px"
                      />
                    </td>
                    <td
                      aria-colindex="1"
                      style="width: 100px;"
                    >
                      <ContentLoader
                        height="2px"
                        width="100px"
                      />
                    </td>
                    <td
                      aria-colindex="1"
                      style="width: 100px;"
                    >
                      <ContentLoader
                        height="2px"
                        width="100px"
                      />
                    </td>
                    <td
                      aria-colindex="1"
                      style="width: 100px;"
                    >
                      <ContentLoader
                        height="2px"
                        width="100px"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </template>

          <template #loaded>
            <BTable
              v-if="permissions.length > 0"
              id="permissions"
              striped
              hover
              responsive
              :items="permissions"
              :fields="fields"
              :sort-by="tableSortBy"
              sort-null-last
            >
              <template #cell(environment)="data">
                <EnvironmentComponent
                  v-if="data.item.role"
                  :production="data.item.environment === 'prod'"
                />
              </template>
              <template #cell(certificationData)="data">
                <div>
                  <span v-if="data.item.certificationData && data.item.certificationData.expiresIn">
                    {{ data.item.certificationData.expiresIn }} day(s)
                  </span>
                </div>
              </template>
            </BTable>
            <div
              v-if="permissions.length === 0"
              class="alert alert-light"
            >
              You don't have any permissions.
            </div>
          </template>
        </Bubblewrap>
      </BCol>
    </BRow>
  </BContainer>
</template>

<script setup lang="ts">
import axios from 'axios';
import {
  reactive, onMounted,
} from 'vue';
import {
  BTable, BContainer, BRow, BCol,
  BTableSortBy,
} from 'bootstrap-vue-next';
import Bubblewrap from '@/components/BubblewrapComponent.vue';
import EnvironmentComponent from '@/components/EnvironmentComponent.vue';
import { BubblewrapInfo } from '@/models/bubblewrap';
import { Permission } from '@/models/keycloak';
import { getKeycloakRequestConfig } from '@/utils/http';
import { userStore } from '@/store/modules/user';

const permissions: Permission[] = reactive([]);
const user = userStore();
const fields = [
  {
    key: 'group',
    label: 'Group name',
    sortable: true,
    class: 'align-middle',
  },
  {
    key: 'environment',
    label: 'Environment',
    sortable: true,
    class: 'align-middle',
  },
  {
    key: 'role',
    label: 'Role',
    sortable: true,
    class: 'align-middle',
  },
  {
    key: 'certificationData',
    label: 'Certification expires in',
    sortable: true,
    class: 'align-middle',
  },
];

const tableSortBy: BTableSortBy[] = [{ key: 'group', order: 'asc' }];

const apiCall: BubblewrapInfo = reactive({
  error: null,
  loaded: false,
});

async function fetchPermissions(): Promise<Permission[]> {
  return axios
    .get<Permission[]>(
      `/users/${user.id}/permissions`,
      getKeycloakRequestConfig(user.xsrfToken),
    )
    .then((response) => response.data as Permission[]);
}

onMounted(async () => {
  try {
    const permissionsData = await fetchPermissions();
    Object.assign(permissions, permissionsData);

    apiCall.loaded = true;
  } catch (e) {
    console.log(e);
    apiCall.error = e;
  }
});

</script>
