<template>
  <div class="container mt-4">
    <div class="row gx-5">
      <div class="col">
        <BFormInput v-model="nameFilter" data-element="tenants-searchbar" placeholder="Search..." />
      </div>
      <div class="col" />
    </div>
    <div class="row mt-4">
      <div class="col">
        <BTable
          v-if="!!items.length"
          data-element="tenants-table"
          class="align-middle"
          striped
          :sort-by="[{ key: TableFieldKey.TENANT_NAME, order: 'asc' }]"
          :fields="fields"
          :items="items"
          @row-clicked="navigateToTenantDetails"
        >
          <template #cell(tenantName)="data">
            <BLink data-element="tenant-name-column" class="text-decoration-none" :to="{ name: 'tenantDetails', params: { tenantName: data.item.tenantName } }">
              {{ data.item.tenantName }}
            </BLink>
          </template>
          <template #cell(actions)="data">
            <BDropdown text="Actions" size="sm" data-element="tenant-actions">
              <BDropdownItem v-for="menuItem of data.item.menu" :key="menuItem.label">
                <BLink class="btn btn-sm w-100" :to="menuItem.to">
                  {{ menuItem.label }}
                </BLink>
              </BDropdownItem>
            </BDropdown>
          </template>
        </BTable>
        <div v-else>
          <span data-element="no-tenants-fallback">No tenants to show.</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { TenantRepresentation } from '@/models/keycloak';
import {
  BLink, BFormInput, BTable, TableFieldRaw,
} from 'bootstrap-vue-next';
import Utils from '@/utils/utils';
import { RouteLocationRaw, useRouter } from 'vue-router';

type Props = {
  tenants: TenantRepresentation[];
};

type TenantMenuItem = { to: RouteLocationRaw, label: string };

type TenantMenu = TenantMenuItem[];

type TenantRow = {
  tenantName: string,
  role: string,
  menu: TenantMenu
}

const { tenants } = defineProps<Props>();
const nameFilter = ref('');
const router = useRouter();

enum TableFieldKey {
  TENANT_NAME = 'tenantName',
  ROLE = 'role',
  ACTIONS = 'actions'
}

const fields: Exclude<TableFieldRaw<TenantRow>, string>[] = [
  { key: TableFieldKey.TENANT_NAME, sortable: true },
  { key: TableFieldKey.ROLE, label: 'Portal Permission', sortable: true },
  { key: TableFieldKey.ACTIONS, label: '', tdClass: 'text-end' },
];

const filterByTenantName = ({ tenantName }: TenantRepresentation) => tenantName
  .toLocaleLowerCase()
  .includes(nameFilter.value.toLocaleLowerCase());

const mapTenantToTableItem = (tenant: TenantRepresentation): TenantRow => {
  const firstRole = Utils.sort(tenant.permissions).at(0);
  const role = firstRole === 'viewer' ? 'readonly' : firstRole;
  const menu: TenantMenu = [
    {
      label: 'Details',
      to: { name: 'tenantDetails', params: { tenantName: tenant.tenantName } },
    },
  ];

  if (tenant.permissions.includes('approver')) {
    menu.push({
      label: 'Manage',
      to: { name: 'tenantSettings', params: { tenantName: tenant.tenantName } },
    });
  }

  return {
    ...tenant,
    role,
    menu,
  };
};

const navigateToTenantDetails = ({ tenantName }: TenantRow) => {
  router.push({ name: 'tenantDetails', params: { tenantName } });
};

const items = computed(() => tenants
  .filter(filterByTenantName)
  .map(mapTenantToTableItem));

</script>
