<template>
  <div class="overflow-auto ">
    <BTable
      :per-page="0"
      :current-page="currentPage"
      :items="pageSize"
      :fields="props.fields"
      primary-key="_uniqueId"
      striped
      hover
      responsive
    >
      <template #cell(environment)="data">
        <EnvironmentComponent :production="data.item.environment === 'prod'" />
      </template>
      <template #head(´)>
        Certification expires in
        <font-awesome-icon
          v-b-modal="'certification-info'"
          class="modal-cta"
          icon="question-circle"
        />
      </template>
      <template #cell(certificationData)="data">
        <div v-if="hasCertificationData(data.item)">
          <span
            v-if="data.item.certificationData"
          >{{ data.item.certificationData.expiresIn }} day(s)</span>
          <span v-else>
            <span class="badge text-bg-danger">not certified</span>
          </span>
        </div>
        <div v-if="!hasCertificationData(data.item)">
          &nbsp;
        </div>
      </template>
      <template #cell(lastName)="data">
        <a class="text-decoration-none" :href="`mailto:${data.item.email}`">
          {{ data.item.firstName }} {{ data.item.lastName }}
        </a>
      </template>

      <template #cell(actions)="data">
        <TenantUserActions
          :tenant-member="data.item"
          @recertified="(message) => userRecertified(data.item, message)"
          @removed="(message) => userRemoved(data.item, message)"
          @error="(error) => handleUserActionError(error)"
        />
      </template>
    </BTable>
    <div class="d-flex justify-content-end">
      <BPagination
        v-if="showPagination"
        v-model="currentPage"
        :total-rows="rows"
        :per-page="props.perPage"
        aria-controls="my-table"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  ref, computed, watch, reactive,
} from 'vue';
import { BTable, BPagination } from 'bootstrap-vue-next';
import TenantUserActions from '@/components/TenantUserActions.vue';
import EnvironmentComponent from '@/components/EnvironmentComponent.vue';
import { Permission } from '@/models/keycloak';

const props = defineProps({
  fields: {
    type: Array,
    required: true,
  },
  users: {
    type: Array,
    required: true,
  },
  disabledFields: {
    type: Array,
    required: true,
  },
  toast: {
    type: Function,
    required: true,
  },
  tableId: {
    type: String,
    default: 'my-table',
  },
  perPage: {
    type: Number,
    required: true,
  },
});

const emits = defineEmits(['removed']);

const currentPage = ref(1);

const { toast } = props;

const rows = computed(() => props.users.length);

const pageSize = computed(() => {
  const paginatedItems = reactive([]);
  paginatedItems.splice(0, paginatedItems.length);
  const startIndex = (currentPage.value - 1) * props.perPage;
  const endIndex = startIndex + props.perPage;

  paginatedItems.push(...props.users.slice(startIndex, endIndex));

  return paginatedItems;
});

const hasCertificationData = (tenantMember: Permission) => {
  const { environment, role } = tenantMember;
  return environment !== 'prod' && role !== 'escalator' && role !== 'responsible';
};

const showPagination = computed(() => props.users.length > props.perPage);

watch(
  () => props.perPage,
  () => {
    currentPage.value = 1;
  },
);

/**
 * Triggered when a user has been recertified through TenantUserActions
 *
 * The certification data is updated in the local storage to avoid having
 * to make new requests for all users.
 */
function userRecertified(
  tenantMember: Permission,
  message: { title: string, message: string, certificationData: any },
) {
  toast('success', message.title, message.message);

  const storedUser = props.users.find(
    (u: Permission) => u.id === tenantMember.id && u.role === tenantMember.role,
  ) as Permission;
  storedUser.certificationData = message.certificationData;
}

/**
 * Triggered when a user was removed through TenantUserActions
*
* We locally update the "users" array to represent the change.
 */
function userRemoved(tenantMember: Permission, message: { title: string, message: string }) {
  toast('success', message.title, message.message);
  emits('removed', tenantMember);
}

/**
* Handles errors from the TenantUserAction component. We only display the error
* in a toast message.
 *
 * This will only trigger for HTTP Errors from the resulting requests of actions.
 */
const handleUserActionError = (message: { title: string, message: string }) => {
  toast('danger', message.title, message.message);
};

</script>
