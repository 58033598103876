<template>
  <div>
    <BProgress
      :value="done + error"
      :max="assignPermissionRequests.length"
    >
      <BProgressBar
        :value="done"
        variant="success"
      >
        {{ done }} successful
      </BProgressBar>
      <BProgressBar
        :value="error"
        variant="danger"
      >
        {{ error }}
        {{ error > 1 ? 'errors' : 'error' }}
      </BProgressBar>
      <BProgressBar
        :animated="pending > 0"
        :value="pending"
        variant="info"
      >
        {{ pending }} pending
      </BProgressBar>
    </BProgress>

    <BTable
      :items="items"
      :fields="fields"
      small
      class="mt-2"
    >
      <template #cell(state)="data">
        <span
          :id="`tooltip-${data.index}`"
          v-b-tooltip="data.item.stateReason"
          :style="data.item.stateReason && stateReasonStyle"
        >
          <font-awesome-icon
            v-if="data.item.state === RequestState.DONE
              || data.item.state === RequestState.ERROR"
            :icon="icon(data.item.state as RequestState).icon"
            :color="icon(data.item.state as RequestState).color"
            :title="data.item.state"
          />
          <BSpinner
            v-else
            small
          />
        </span>
      </template>
    </BTable>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useBulkPermissionAssignment, RequestState } from '@/store/modules/useBulkPermissionAssignment';
import {
  BTable, BSpinner, BProgressBar, BProgress,
} from 'bootstrap-vue-next';

const store = useBulkPermissionAssignment();
const {
  assignPermissionRequests, error, done, pending,
} = storeToRefs(store);

const stateReasonStyle = {
  'border-bottom': 'black 2px dotted',
  'padding-bottom': '0px',
  cursor: 'pointer',
};

const icon = (state: RequestState) => {
  switch (state) {
    case RequestState.DONE:
      return { icon: 'check', color: 'green' };
    case RequestState.ERROR:
      return { icon: 'exclamation-triangle', color: 'red' };
    default:
      return { icon: 'spinner', color: 'grey' };
  }
};

const items = computed(() => assignPermissionRequests.value
  .map(({ permission: { user, role, tenant }, state, stateReason }) => ({
    user: user.email,
    tenant: tenant.name,
    role: role.name,
    state,
    stateReason,
  })));

const fields = [
  { key: 'user', sortable: true },
  { key: 'tenant', sortable: true },
  { key: 'role', sortable: true },
  { key: 'state', sortable: true },
];
</script>
@/store/modules/useBulkPermissionAssignment
