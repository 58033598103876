import {
  computed, reactive, ref,
} from 'vue';
import axios from 'axios';
import { Suggestion } from '@/models/typeahead';
import Validator from '@/utils/validator';
import { getKeycloakRequestConfig } from '@/utils/http';
import { userStore } from '@/store/modules/user';
import { SelectOptionsDropDown } from '@/models/tenant';

function escapeRegExp(str: string) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function sanitize(text: string) {
  return text.replace(/</g, '&lt;').replace(/>/g, '&gt;');
}

export function userExists(userTypeaheadSuggestions: Suggestion[], username: string) {
  if (userTypeaheadSuggestions.length === 0) {
    console.log('no suggestions', userTypeaheadSuggestions, username);
    return false;
  }

  const re = new RegExp(escapeRegExp(sanitize(username)), 'gi');
  const filteredList = userTypeaheadSuggestions.filter(
    (suggestion) => suggestion.username.match(re) !== null,
  );
  console.log('filteredList', filteredList.length);
  return filteredList.length > 0;
}

function validEmail(email: string) {
  return Validator.isValidEmail(email);
}

export default function useTenantComponent() {
  const username = ref('');
  const role = ref('Choose a role...');
  const duration = ref(SelectOptionsDropDown.DURATION);
  const reason = ref(null);
  const typeaheadProcess = ref(false);
  const userTypeaheadSuggestions = reactive([]);
  const user = userStore();

  const usernameIsValid = computed(() => {
    if (username.value != null) {
      return validEmail(username.value);
    }
    return null;
  });

  function showUserMissing(suggestion) {
    if (!username.value) {
      return false;
    }
    return usernameIsValid.value && userExists(suggestion, username.value);
  }

  const roleIsValid = computed(() => {
    if (role.value !== SelectOptionsDropDown.ROLE) {
      return role.value.length > 0;
    }
    return null;
  });

  async function getTypeaheadUser(searchString: string): Promise<Suggestion[]> {
    const getConfiguration = getKeycloakRequestConfig(user.xsrfToken);
    getConfiguration.params = { query: searchString };

    typeaheadProcess.value = true;

    try {
      const response = await axios.get<Suggestion[]>('/users', getConfiguration);
      return response.data as Suggestion[];
    } finally {
      typeaheadProcess.value = false;
    }
  }

  return {
    username,
    role,
    duration,
    reason,
    typeaheadProcess,
    userTypeaheadSuggestions,
    usernameIsValid,
    showUserMissing,
    roleIsValid,
    getTypeaheadUser,
  };
}
