// store/user.ts
import { defineStore } from 'pinia';
import axios from 'axios';
import { Permission } from '@/models/keycloak';
import { getKeycloakRequestConfig } from '@/utils/http';
import { userStore } from './user';

export const usePermissions = defineStore('usePermissions', {
  state: () => ({
    permissions: [] as Permission[],
  }),
  getters: {
    isApprover: (state) => state.permissions.some((permission) => permission.role === 'approver'),
  },
  actions: {
    async fetchPermissions() {
      const user = userStore();

      const request = await axios
        .get<Permission[]>(
          `/users/${user.id}/permissions`,
          getKeycloakRequestConfig(user.xsrfToken),
        );

      this.permissions = request.data as Permission[];
    },
  },
});
