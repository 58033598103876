<template>
  <div
    id="onboarding-wizard-select-tenant-role"
    class="mt-5"
  >
    <BTableSimple
      responsive
      small
      striped
      bordered
    >
      <BThead>
        <BTr>
          <BTh
            sticky-column
            is-row-header
            style="width: 240px;max-width: 240px;"
          >
            &nbsp;
          </BTh>
          <BTh
            v-for="role in roles"
            :key="role.name"
            style="width: 120px;
              word-wrap: break-word;
              max-width: 120px;
              text-align: center;
              word-break: break-all;"
          >
            {{ role.name }}
            <BFormCheckbox
              :checked="allSelected(role)"
              @change="toggleRole(role)"
            />
          </BTh>
        </BTr>
      </BThead>
      <BTbody>
        <BTr
          v-for="tenant in tenants"
          :key="tenant.name"
        >
          <BTh sticky-column>
            {{ tenant.name }}
          </BTh>
          <BTd
            v-for="role in roles"
            :key="role.name"
            style="text-align: center"
          >
            <BFormCheckbox
              v-if="tenantRoles.some((a) => a.role === role && a.tenant === tenant)"
              v-model="selectedTenantRoles"
              :value="reactive(tenantRoles.find((a) => a.role === role && a.tenant === tenant))"
            />
          </BTd>
        </BTr>
      </BTbody>
    </BTableSimple>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { reactive } from 'vue';
import {
  BTh, BFormCheckbox, BTr, BTd, BTableSimple, BThead, BTbody,
} from 'bootstrap-vue-next';
import { Role } from '@/models/bulkPermissionManagement';
import { useBulkPermissionManagement } from '@/store/modules/useBulkPermissionManagement';
import { useTenants } from '@/store/modules/useTenants';

const store = useBulkPermissionManagement();
const { selectedTenantRoles } = storeToRefs(store);

const tenantStore = useTenants();
const { tenants, roles, tenantRoles } = storeToRefs(tenantStore);

const allSelected = (role: Role) => {
  const tenantRolesToSelect = tenantRoles.value.filter((a) => a.role === role).map(reactive);
  return tenantRolesToSelect.every((a) => selectedTenantRoles.value.includes(a));
};

function toggleRole(role: Role) {
  const tenantRolesToSelect = tenantRoles.value.filter((a) => a.role === role).map(reactive);

  if (allSelected(role)) {
    selectedTenantRoles.value = [
      ...selectedTenantRoles.value.filter((a) => !tenantRolesToSelect.includes(a)),
    ];
  } else {
    selectedTenantRoles.value = [
      ...new Set([...selectedTenantRoles.value, ...tenantRolesToSelect])];
  }
}
</script>
