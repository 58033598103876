<template>
  <div>
    <div class="mb-4 toggle-header">
      <button
        type="button"
        class="toggle-button"
        :aria-expanded="isVisible"
        :aria-controls="toggleId"
        @click="toggleVisibility"
      >
        <span :class="{ h5: isHeader }">{{ header }}</span>
        <span class="toggle-icon" />
      </button>
    </div>

    <BCollapse
      :id="toggleId"
      :visible="isVisible"
      :aria-labelledby="toggleId"
    >
      <slot />
    </BCollapse>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { BCollapse } from 'bootstrap-vue-next';

const props = defineProps({
  isHeader: {
    type: Boolean,
  },
  header: {
    type: String,
    required: true,
  },
  toggleId: {
    type: String,
    required: true,
  },
  isVisible: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:isVisible']);

const isVisible = ref(props.isVisible);

const toggleVisibility = () => {
  isVisible.value = !isVisible.value;
  emit('update:isVisible', isVisible.value);
};

// Sync prop change to internal state
watch(() => props.isVisible, (newVal) => {
  isVisible.value = newVal;
});
</script>

<style lang="scss" scoped>
.toggle-header {
  width: 100%;
  position: relative;

  .toggle-button {
    background: none;
    border: none;
    width: 100%;
    text-align: left;

    &:focus,
    &:hover,
    &:active {
      outline: none;
    }

    .toggle-icon {
      position: absolute;
      padding: 15px;
      right: 0;
      top: 0;

      &::before,
      &::after {
        transition-property: transform;
        transition-duration: 250ms;
        transition-timing-function: ease;
        content: "";
        position: absolute;
        top: 50%;
        width: 11px;
        height: 2px;
        transform-origin: bottom;
        background-color: #000;
      }

      &::before {
        right: 50%;
        transform: translateY(-50%) translateX(1.5px) rotate(45deg);
      }

      &::after {
        left: 50%;
        transform: translateY(-50%) translateX(-1.5px) rotate(-45deg);
      }
    }

    &[aria-expanded="true"] .toggle-icon {
      &::before {
        right: 50%;
        transform: translateY(-50%) translateX(3px) rotate(-45deg);
      }

      &::after {
        left: 50%;
        transform: translateY(-50%) translateX(-3px) rotate(45deg);
      }
    }
  }
}
</style>
