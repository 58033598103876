import { defineStore } from 'pinia';
import { useStorage } from '@vueuse/core';
import { useBulkPermissionManagement } from './useBulkPermissionManagement';
import { useBulkPermissionAssignment } from './useBulkPermissionAssignment';

export const useBulkPermissionWizard = defineStore('useBulkPermissionWizard', {
  state: () => ({
    step: 0,

    skipIntro: useStorage('on-boarding-wizard:skipIntroduction', false),
  }),

  getters: {
    isValid(): boolean { return useBulkPermissionManagement().isValid; },
    done() {
      const execution = useBulkPermissionAssignment();

      return execution.allDone;
    },
  },

  actions: {
    toggleSkipIntro() {
      this.skipIntro = !this.skipIntro;
    },
  },
});
