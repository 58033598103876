import axios from 'axios';
import { TenantInformation, TenantRole as ExternTenantRole } from '@/models/tenant';
import { getKeycloakRequestConfig } from '@/utils/http';
import { userStore } from '@/store/modules/user';
import { Suggestion } from '@/models/typeahead';
import {
  Permission, Role, Tenant, TenantRole, User,
} from '@/models/bulkPermissionManagement';
import { TenantRepresentation } from '@/models/keycloak';
import * as tenantApi from '@/api/tenant';

/**
 * BulkPermission-internal backend error
 */
class BulkError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'BulkPermissionsBackendError';
  }
}

/**
 * Extract all manageable tenant roles from a tenant information object
 */
function extractManageableTenantRoles(tenantInfo: TenantInformation): ExternTenantRole[] {
  return tenantInfo.roles.filter((tenantRole: ExternTenantRole) => tenantRole.isSelfServiceRole);
}

/**
 * Extract all tenants that the active user can manage
 */
function extractManageableTenants(tenants: TenantRepresentation[]): TenantRepresentation[] {
  return tenants.filter((tenant: TenantRepresentation) => tenant.permissions.includes('approver'));
}

/**
 * Fetch tenants from the backend
 */
async function fetchTenant(tenantName: string): Promise<TenantInformation> {
  try {
    return await tenantApi.fetchTenant(tenantName);
  } catch (err) {
    console.log('error', err);

    if (err.response && err.response.data) {
      throw new BulkError(err.response.data.message);
    }

    throw new BulkError('An error occurred while fetching the tenants');
  }
}

/**
 * Fetch list of tenants from the backend
 *
 * Skips tenants with errors and logs them to the console
 */
async function fetchTenants(
  tenantNames: string[],
): Promise<{ [tenantName: string]: TenantInformation }> {
  const promisesCalls = tenantNames.map((tenant) => fetchTenant(tenant));
  const tenantInfos = await Promise.allSettled(promisesCalls);

  return tenantNames.reduce(
    (acc, tenantName, index) => {
      const tenantInfoSettledResult = tenantInfos[index];

      if (tenantInfoSettledResult.status === 'fulfilled') {
        // FIXME: workaround: couldn't get the type guarding right here
        const fulfilledTenantInfo = tenantInfoSettledResult as
          PromiseFulfilledResult<TenantInformation>;

        return {
          ...acc,
          [tenantName]: fulfilledTenantInfo.value,
        };
      }

      // FIXME: workaround: couldn't get the type guarding right here
      const rejectedTenantInfo = tenantInfoSettledResult as unknown as PromiseRejectedResult;

      console.error('failed to fetch tenant', tenantName, rejectedTenantInfo.reason);
      return acc;
    },
    {},
  );
}

/**
 * Fetch list of all tenants of the active user from the backend
 */
async function fetchTenantsOfActiveUser(): Promise<TenantRepresentation[]> {
  const userSession = userStore();

  const userId = userSession.id;

  const url = `/users/${userId}/tenants`;

  try {
    const response = await axios.get<TenantRepresentation[]>(
      url,
      getKeycloakRequestConfig(userSession.xsrfToken),
    );

    return response.data;
  } catch (err) {
    console.log('error', err);

    if (err.response && err.response.data) {
      throw new BulkError(err.response.data.message);
    }

    throw new BulkError('An error occurred while fetching the tenants');
  }
}

/**
 * Fetch all tenant of the active user from the backend and return them in a bulk-domain
 * representation
 */
export async function fetchTenantRoles(): Promise<TenantRole[]> {
  const tenantRepresentation = await fetchTenantsOfActiveUser();

  const tenantNames = extractManageableTenants(tenantRepresentation).map(
    (tenant) => tenant.tenantName,
  );

  const tenants = await fetchTenants(tenantNames);

  const uniqueRolesByTechnicalName = new Map<string, Role>();

  return Object.entries(tenants)
    .map(([tenantName, tenantInformation]) => {
      const tenant: Tenant = {
        name: tenantName,
      };

      const manageableRoles = extractManageableTenantRoles(tenantInformation);

      const roles: Role[] = manageableRoles.map((role) => {
        if (!uniqueRolesByTechnicalName.has(role.technicalName)) {
          uniqueRolesByTechnicalName.set(role.technicalName, {
            name: role.name,
            technicalName: role.technicalName,
          });
        }

        return uniqueRolesByTechnicalName.get(role.technicalName);
      });

      return roles.map((role) => ({
        tenant,
        role,
      }));
    })
    .flat();
}

/**
 * Fetch users from the backend and return them in a bulk-domain
 * representation
 */
export async function fetchUsers(searchString: string): Promise<User[]> {
  const userSession = userStore();

  const getConfiguration = getKeycloakRequestConfig(userSession.xsrfToken);
  getConfiguration.params = { query: searchString };

  const response = await axios.get<Suggestion[]>('/users', getConfiguration);
  const data = response.data as Suggestion[];

  return data.map((suggestion) => ({
    email: suggestion.username,
    id: suggestion.userId,
  }));
}

/**
 * Add a user to a tenant role and return them in a bulk-domain
 * representation
 */
export async function assignPermission(permission: Permission) {
  const url = `/tenants/${permission.tenant.name}/users/${permission.user.id}/roles/${permission.role.technicalName}`;

  const userSession = userStore();

  try {
    await axios.put<Permission>(url, {}, getKeycloakRequestConfig(userSession.xsrfToken));
  } catch (err) {
    console.log('error', err);

    if (err.response && err.response.data) {
      throw new BulkError(err.response.data.message);
    }

    throw new BulkError('An error occurred while adding the user to the tenant role');
  }
}
