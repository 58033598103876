import emailValidator from 'email-validator';
import Utils from './utils';

export default class Validator {
  /**
   * Validate email address
   * @param email
   */
  public static isValidEmail(email: string): boolean {
    return emailValidator.validate(email);
  }

  public static isTimestampValid(date: string, time: string) {
    // time and date are valid and in the future.
    if (this.isDateIsValid(date) && this.isTimeIsValid(time) && Utils.toDate(date, time)) {
      return true;
    }
    return false;
  }

  public static isDateIsValid(date: string) {
    if (date !== null) {
      return true;
    }
    return null;
  }

  public static isTimeIsValid(time: string) {
    // time string of the format HH:mm with seconds as optional
    return /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(time);
  }
}
