import { AxiosRequestConfig } from 'axios';
import Config from '@/utils/config';

export function getKeycloakRequestConfig(xsrfToken: string): AxiosRequestConfig {
  return {
    baseURL: Config.PORTAL_API_URL,
    headers: {
      'X-XSRF-Token': xsrfToken,
    },
    withCredentials: true,
  };
}
