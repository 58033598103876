import { defineStore, storeToRefs } from 'pinia';
import { ref } from 'vue';
import { assignPermission } from '@/api/bulkPermissionManagement';
import { Permission } from '../../models/bulkPermissionManagement';
import { useBulkPermissionManagement } from './useBulkPermissionManagement';

// eslint-disable-next-line no-shadow
export enum RequestState {
  PENDING= 'pending',
  DONE= 'done',
  ERROR= 'error',
}

type AssignPermissionRequest = {
  permission: Permission;
  state: RequestState;
  stateReason: string;
}

export const useBulkPermissionAssignment = defineStore('useBulkPermissionAssignment', {
  state: () => ({
    assignPermissionRequests: [] as AssignPermissionRequest[],
  }),

  getters: {
    amountInRequestState(state): (rs: RequestState) => number {
      const f = (rs: RequestState) => (state.assignPermissionRequests
        .filter(
          ({ state: s }) => s === rs,
        ).length);

      return f;
    },

    pending(): number { return this.amountInRequestState(RequestState.PENDING); },
    error(): number { return this.amountInRequestState(RequestState.ERROR); },
    done(): number { return this.amountInRequestState(RequestState.DONE); },

    allDone(): boolean { return this.pending === 0; },
  },

  actions: {
    assignPermissions() {
      const store = useBulkPermissionManagement();
      const { permissions } = storeToRefs(store);

      const newSate = permissions.value.map((permission) => {
        const request = assignPermission(permission);

        const state = ref<RequestState>(RequestState.PENDING);
        const stateReason = ref<string>('');

        request.then(() => {
          state.value = RequestState.DONE;
        }).catch((e) => {
          stateReason.value = e.message;
          state.value = RequestState.ERROR;
        });

        return {
          permission,
          state,
          stateReason,
        };
      });

      Object.assign(this.assignPermissionRequests, newSate);
    },
  },
});
