import { defineStore } from 'pinia';
import { fetchTenantRoles } from '@/api/bulkPermissionManagement';
import { Tenant, TenantRole } from '../../models/bulkPermissionManagement';

export const useTenants = defineStore('useTenants', {
  state: () => ({
    tenantState: [] as Tenant[],
    tenantRoles: [] as TenantRole[],

    loading: false,
  }),

  getters: {
    roles: (state) => [...new Set(state.tenantRoles.map(({ role }) => role))],
    tenants: (state) => [...new Set(state.tenantRoles.map(({ tenant }) => tenant))],
  },

  actions: {
    async load() {
      this.loading = true;
      try {
        this.tenantRoles = await fetchTenantRoles();
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
