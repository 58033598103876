<template>
  <div class="bg-danger-light p-5 mb-4 text-light rounded-3 full-height">
    <div class="container-fluid py-5">
      <h1 class="display-4 px-5">
        Sneaky
      </h1>
      <p class="lead px-5">
        But not sneaky enough. You need to be logged in to see this page.
      </p>

      <button class="btn btn-light btn-lg mb-4 mx-5" type="button" @click="login()">
        Log me in
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { userStore } from '@/store/modules/user';

const user = userStore();

function login() {
  user.redirectToLogin();
}

</script>

<style lang="sass">
.container-fluid {
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden;
}
</style>

<style lang="sass" scoped>
.bg-danger-light {
  background-color: #dc3545
}

.full-height {
  margin: 0 !important;
  height: calc(100vh - 64px);
  overflow: hidden;
}

.container {
  padding-top: 64px;
}
</style>
