<template>
  <div v-if="hasBasicActions">
    <div
      class="btn-group"
      role="group"
      aria-label="Basic actions"
    >
      <AsyncButton
        id="recertifyUser"
        name="Recertify"
        initial-icon="sync"
        :fn="() => recertifyUser()"
        :disabled="props.tenantMember.id === loggedInUserId"
        @error="(err) => handleRecertificationError(err)"
      />
      <AsyncButton
        id="removeUser"
        name="Remove"
        initial-icon="trash-alt"
        :confirm-modal="removalConfirmationMessage"
        :fn="() => removeUser()"
        @error="() => handleRemovalError()"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import axios, { AxiosError } from 'axios';
import {
  computed, PropType, reactive, ref,
} from 'vue';
import { useRoute } from 'vue-router';
import AsyncButton from '@/components/AsyncButton.vue';
import { getKeycloakRequestConfig } from '@/utils/http';
import { Permission, RecertifyResponse } from '@/models/keycloak';
import { userStore } from '@/store/modules/user';
import { BackendError } from '@/models/errors';

/**
 * This Component represents a button bar for tenant approvers to
 * perform actions on individual users in their tenant such as
 * re-certifying or removing a user.
 */

const props = defineProps({
  tenantMember: {
    type: Object as PropType<Permission>,
    required: true,
  },
});
const emits = defineEmits(['error', 'removed', 'recertified', 'status']);

const user = userStore();
const loggedInUserId = ref(user.id);
const route = useRoute();
const removalConfirmationMessage = computed(() => `You are about to remove access from ${props.tenantMember.username}
     for your tenant ${route.params.tenantName}
     with the role ${props.tenantMember.role}.
     Do you want to continue?`);
const error: BackendError = reactive({
  type: null,
  message: null,
});

const hasBasicActions = computed(() => {
  const { environment, role } = props.tenantMember;
  return (
    environment !== 'prod' && role !== 'escalator' && role !== 'responsible'
  );
});

function handleRecertificationError(axiosError: AxiosError) {
  emits('error', {
    error,
    action: 'recertification',
    title: 'Recertification failed',
    message: `Could not recertify ${props.tenantMember.username}: ${axiosError.response.data}`,
  });
}

function handleRemovalError() {
  emits('error', {
    action: 'remove',
    title: 'Removal failed',
    message: `Could not remove ${props.tenantMember.username}`,
  });
}

/**
     * This function makes a request to the mps portal api to remove a user from the
     * tenant (only for the given role).
     *
     * We do not handle errors in the promise chain because the AsyncButton takes care of this.
     * */
async function removeUser() {
  try {
    const response = await axios
      .delete(
        `/tenants/${route.params.tenantName}/users/${props.tenantMember.id}/roles/${props.tenantMember.role}`,
        getKeycloakRequestConfig(user.xsrfToken),
      );
    if (response.status === 204) {
      console.log('removed user successful');
      emits('removed', {
        title: 'Removal successful',
        message: `${props.tenantMember.username} has been removed from the role ${props.tenantMember.role}.`,
      });
    }
  } catch (err) {
    console.log('error', err);
    if (err.response && err.response.data) {
      error.message = err.response.data.message;
      emits('error', error.message);
    }
    throw error;
  }
}

/**
     * This function makes a call to the mps portal api to recertify a user
     * for the tenant role.
     *
     * We do not handle errors in the promise chain because the AsyncButton takes care of this.
     */
async function recertifyUser() {
  return axios
    .post<RecertifyResponse>(
      `/tenants/${route.params.tenantName}/recertification/${props.tenantMember.id}/${props.tenantMember.role}`,
      null,
      getKeycloakRequestConfig(user.xsrfToken),
    )
    .then((response) => {
      if (response.data) {
        emits('recertified', {
          title: 'Recertification successful',
          message: `${props.tenantMember.username} has been recertified for role ${props.tenantMember.role}.`,
          certificationData: {
            certifiedAt: response.data.certifiedAt,
            certifiedBy: response.data.certifiedBy,
            expiresAt: response.data.expiresAt,
            expiresIn: response.data.expiresIn,
          },
        });
      }

      return response.data;
    });
}

</script>
